




































import { Component, Vue, Prop } from "vue-property-decorator";
import MainHeader from "../../main/components/main-header/main-header.vue";
import NavLeft from "../../main/components/nav-left/nav-left.vue";
import CepingEdit from "../../workstation/components/jiankangceping/ceping-edit.vue";
Component.registerHooks([
  "beforeRouteEnter",
  "beforeRouteLeave",
  "beforeRouteUpdate",
]);
@Component({
  components: {
    MainHeader,
    NavLeft,
    CepingEdit,
  },
})
export default class Name extends Vue {
  private pageType: any = "";
  private routerPath: any = "";
  private content_id: any = "";
  private template_id: any = "";
  private ifShowSaveConfirm: any = false;
  private noSaveText: any = "不保存，离开";
  private refreshDataTime: any = "";

  private get patient() {
    return this.$store.state.patient;
  }
  private get ifCompose() {
    return this.$store.state.ifCompose;
  }
  /**
   * @description 离开页面前保存
   */
  private beforeRouteLeave(to: any, from: any, next: any) {
    this.routerPath = to.fullPath;
    if (this.ifCompose) {
      this.ifShowSaveConfirm = true;
      next(false);
    } else {
      next(true);
    }
  }
  private refresh(e: any) {
    this.content_id = e.content_id;
  }

  private mounted() {
    if (this.$route.query.content_id) {
      this.content_id = this.$route.query.content_id;
      this.refreshDataTime = new Date().getTime();
    }
    if (this.$route.query.type) {
      this.pageType = this.$route.query.type;
    }
    if (this.$route.query.template_id) {
      this.template_id = this.$route.query.template_id;
      this.refreshDataTime = new Date().getTime();
    }
  }
}
